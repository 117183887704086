.react-datepicker__input-container input {
  padding: 10px 5px 8px 10px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  width: 100%;
  color: #222;
}

.react-datepicker__day--in-range {
  background-color: #30519f;
}

.react-datepicker__day--in-range:hover {
  background-color: #0e2a6b;
}
