* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

#root {
  position: absolute;
  top: 0;
  left: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

a,
a:hover,
a:active {
  text-decoration: none;
  color: #30519f;
}

ul,
ol {
  list-style: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}

@media (min-width: 900px) {
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c6c6c6;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #c6c6c6;
  }
}

.page {
  background: white;
  width: 25cm;
  margin: 0 auto;
  display: block;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.land-scape {
  background: white;
  width: 40cm;
  overflow: auto;
  margin: 0 auto;
  display: block;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.page span,
.land-scape span,
p {
  line-height: 1.4em;
  color: #000;
}

.page span,
.land-scape span,
p {
  font-size: 16px;
}

.page h6,
.land-scape h6 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.page > .header,
.land-scape > .header {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.page > .table-header p,
.land-scape > .table-header p {
  font-weight: bold;
  font-size: 14px;
}

.page .right,
.land-scape .right {
  text-align: right;
}

.page .center,
.land-scape .center {
  text-align: center;
}

@media print {
  html,
  body,
  #root {
    overflow: initial;
  }

  body,
  .page,
  .land-scape {
    margin: 0;
    box-shadow: none;
    width: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    line-height: 1.4em;
    font-size: 14px;
    font-family: sans-serif;
    color: #000;
    background: transparent !important;
  }
}

@page {
  margin: 1cm;
}
